import { combineReducers } from "redux";
import authReducer from "./auth/authSlice";
import webReducer from "./user/userSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  web: webReducer,
});

export default rootReducer;
