import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, IconButton, Tab, Tabs } from "@mui/material";
import userIcon from "../../../src/assets/images/userIcon.svg";
import VehicleRegister from "components/commonComponent/vehicleRegister";
import DriverAssign from "components/commonComponent/driverAssign";
import DriverVehicle from "components/commonComponent/driverInfo/driverVehicle";
import DriverCompany from "components/commonComponent/driverInfo/driverCompany";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  maxWidth: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  border: "none !important",
};
const headingStyle = {
  color: "#344767",
  fontSize: "0.875rem",
  fontWeight: "600",
  textTransform: "capitalize",
  textAlign: "left",
};
const dataStyle = {
  color: " rgba(99, 115, 129, 1)",
  fontSize: "0.875rem",
  fontWeight: "500",
  textTransform: "capitalize",
  textAlign: "left",
};

const UserDetailModal = (props) => {
  const { open, setOpen, userTitle, userDetail } = props;
  const handleClose = () => {
    setOpen(false);
    setValue(0);
  };
  const UserData =
    userTitle === "Driver Detail"
      ? userDetail?.driverDetails
      : userDetail?.userDetails;

  const vehicleData = userDetail?.userVehicles;
  const driverData = userDetail?.driversRegistered;
  const driverVehicleData = userDetail?.driverVehicles;
  const driverCompanyData = userDetail?.driverCompaniesData;

  const [value, setValue] = useState(0);
  console.log(value, "value");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(userDetail, "userDetail");

  const getGenderLabel = (gender) => {
    switch (gender) {
      case 1:
        return "Male";
      case 2:
        return "Female";
      case 3:
        return "Other";
      default:
        return "Not Specified"; // Optional: Handle undefined gender values
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* Close Button */}
        <IconButton
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
          onClick={handleClose}
        >
          <CancelIcon />
        </IconButton>

        {/* Modal Title */}
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            mt: 2,
            borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
            paddingBottom: "16px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            {userTitle}
          </Typography>
        </Box>

        {/* Image and User Details */}
        <Box sx={{ margin: "12px 0" }}>
          <Grid container spacing={2} alignItems="center">
            {/* Profile Image */}
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <img
                src={
                  UserData?.profile_image ? UserData?.profile_image : userIcon
                }
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
              />
            </Grid>

            {/* User Info */}
            <Grid item xs={12} sm={9} md={10}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* Company Name */}
                {userTitle === "Company Detail" && (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <Typography sx={headingStyle}>Company Name :</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography sx={dataStyle}>
                        {UserData?.company_name}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {/* User Name */}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <Typography sx={headingStyle}>Name :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={dataStyle}>
                      {UserData?.full_name}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Gender */}
                {UserData?.role_id !== 2 && (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <Typography sx={headingStyle}>Gender :</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography sx={dataStyle}>
                        {UserData ? getGenderLabel(UserData.gender) : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {/* Mobile Number */}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <Typography sx={headingStyle}>Mobile no. :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={dataStyle}>
                      {UserData?.country_code}
                      {UserData?.phone_no}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Tabs Section */}
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              background:
                "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
              padding: "0.4rem",
              borderRadius: "0.5rem 0.5rem 0 0",
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "16px",
                color: "#ffffff !important",
              },
              "& .MuiTabs-indicator": {
                background: "rgba(255, 255, 255, 0.15)",
                boxShadow: "none",
              },
            }}
          >
            <Tab label="Vehicles Detail" disableRipple />
            <Tab
              label={UserData?.role_id === 3 ? "Owner Detail" : "Driver Detail"}
              disableRipple
            />
          </Tabs>

          {UserData?.role_id === 3 ? (
            <>
              {value === 0 && <DriverVehicle VehicleData={driverVehicleData} id={UserData?.id}/>}
              {value === 1 && <DriverCompany CompanyData={driverCompanyData} />}
            </>
          ) : (
            <>
              {value === 0 && <VehicleRegister vehicleData={vehicleData} />}
              {value === 1 && <DriverAssign driverData={driverData} />}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default UserDetailModal;
