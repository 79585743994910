import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import moment from "moment";

// Styles for typography and card
const headingStyle = {
  color: "#000000",
  fontSize: "16px",
  fontWeight: 700,
  textAlign: "left",
  marginBottom: "2px",
};

const subheadingStyle = {
  color: "#555555",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
};

const cardStyle = {
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
  marginBottom: "20px",
};

const DriverVehicle = ({ VehicleData, id }) => {
  if (!VehicleData || VehicleData.length === 0) {
    return (
      <Box
        sx={{
          background: "#f5f5f5",
          padding: "24px",
          borderRadius: "8px",
          margin: "0 auto",
          height: "calc(52vh - 100px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
        >
          No Vehicle Details Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        background: "#f5f5f5",
        padding: "24px",
        borderRadius: "8px",
        margin: "0 auto",
        height: "calc(52vh - 100px)",
        overflowY: "auto", // Enable vertical scrolling for the main container
      }}
    >
      {VehicleData.map((vehicle) => {
        const assignedDate =
          vehicle.main_driver_id === id
            ? vehicle.main_driver_vehicle_assigned_date
            : vehicle.reserve_driver_id === id
              ? vehicle.reserve_driver_vehicle_assigned_date
              : "N/A";

        return (
          <Paper key={vehicle.id} sx={cardStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography sx={headingStyle}>Vehicle Name:</Typography>
                <Typography sx={subheadingStyle}>
                  {vehicle?.vehicle_details?.model || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={headingStyle}>Owner Name:</Typography>
                <Typography sx={subheadingStyle}>
                  {vehicle?.vehicle_details?.user_details?.full_name || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={headingStyle}>Assigned Date:</Typography>
                <Typography sx={subheadingStyle}>
                  {moment(assignedDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={headingStyle}>Vehicle Number:</Typography>
                <Typography sx={subheadingStyle}>
                  {vehicle?.vehicle_details?.vehicle_number || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </Box>
  );
};

export default DriverVehicle;
