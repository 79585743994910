import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import moment from "moment";

// Styles
const headingStyle = {
  color: "#000000",
  fontSize: "16px",
  fontWeight: 700,
  textAlign: "left",
  marginBottom: "2px",
};

const subheadingStyle = {
  color: "#555555",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
};

const cardStyle = {
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
  marginBottom: "20px",
  // minHeight: "245px", // Set a max height to enable scrolling
  overflow: "auto", // Make the content inside scrollable
};

const DriverAssign = ({ driverData }) => {
  // Calculate total drivers
  if (!driverData || driverData.length === 0) {
    return (
      <Box
        sx={{
          background: "#f5f5f5",
          padding: "24px",
          borderRadius: "8px",
          margin: "0 auto",
          height: "calc(52vh - 100px)",
          overflowY: "auto", // Enable vertical scrolling for the main container
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
        >
          No Driver Details Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        background: "#f5f5f5",
        padding: "24px",
        borderRadius: "8px",
        margin: "0 auto",
        height: "calc(52vh - 100px)",
        overflowY: "auto", // Enable vertical scrolling for the main container
      }}
    >
      {driverData.map((driver) => (
        <Paper key={driver.id} sx={cardStyle}>
          <Typography sx={headingStyle}>Driver Name:</Typography>
          <Typography sx={subheadingStyle}>
            {driver.driver_details?.full_name || "N/A"}
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "8px" }}>
            {driver.driver_details?.assigned_vehicles_as_main_driver.map(
              (assignment) => (
                <Grid item xs={12} key={assignment.id}>
                  <Paper sx={{ padding: "16px", borderRadius: "8px" }}>
                    <Typography sx={headingStyle}>
                      Vehicle Model:{" "}
                      {assignment.vehicle_details?.model || "N/A"}
                    </Typography>
                    <Typography sx={subheadingStyle}>
                      Vehicle Number:{" "}
                      {assignment.vehicle_details?.vehicle_number || "N/A"}
                    </Typography>
                    <Typography sx={subheadingStyle}>
                      Assigned as Main Driver on:{" "}
                      {moment(
                        assignment.main_driver_vehicle_assigned_date
                      ).format("DD/MM/YYYY")}
                    </Typography>
                  </Paper>
                </Grid>
              )
            )}

            {driver.driver_details?.assigned_vehicles_as_reserve_driver.map(
              (assignment) => (
                <Grid item xs={12} key={assignment.id}>
                  <Paper sx={{ padding: "16px", borderRadius: "8px" }}>
                    <Typography sx={headingStyle}>
                      Vehicle Model:{" "}
                      {assignment.vehicle_details?.model || "N/A"}
                    </Typography>
                    <Typography sx={subheadingStyle}>
                      Vehicle Number:{" "}
                      {assignment.vehicle_details?.vehicle_number || "N/A"}
                    </Typography>
                    <Typography sx={subheadingStyle}>
                      Assigned as Reserve Driver on:{" "}
                      {moment(
                        assignment.reserve_driver_vehicle_assigned_date
                      ).format("DD/MM/YYYY")}
                    </Typography>
                  </Paper>
                </Grid>
              )
            )}
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default DriverAssign;
