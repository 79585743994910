import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, selectLoginAuth } from "../../../redux/auth/authSlice";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import authLogo from "../../../../src/assets/images/authLogo.png"
import authBg from "../../../../src/assets/images/authBg.png"

function Basic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useSelector(selectLoginAuth);

  const schema = yup.object().shape({
    email: yup.string().required("This is a required field"),
    password: yup.string().required("This is a required field"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  const onSave = (data) => {
    dispatch(loginAsync(data));
  };

  // const onSave = (data) => {
  //   dispatch(loginAsync(data))
  //     .then((response) => {
  //       if (response.status === 200) {  // Check if response is 200
  //         navigate("/dashboard");       // Navigate to the dashboard
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Login failed", error); // Handle error
  //     });
  // };

  return (
    <BasicLayout image={authBg}>
      <Card>
        <Box
          mx={2}
          mt={-5}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img src={authLogo} />
        </Box>
        <Typography sx={{
          textAlign: "center",
          color: "#00142D",
          fontSize: "24px",
          fontWeight: "700",
          textTransform: "capitalize"
        }}
        >
          Sign in to your account
        </Typography>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(onSave)}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth {...register("email")} />
              {errors.email && <Typography
                sx={{
                  fontSize: 10,
                  color: "red"
                }}
              >
                {errors.email?.message}</Typography>}
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth {...register("password")} />
              {errors.password && <Typography
                sx={{
                  fontSize: 10,
                  color: "red"
                }}
              >{errors.password?.message}</Typography>}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                // color="info"
                fullWidth
                type="submit"
                disabled={loading} // Disable button while loading
                sx={{
                  backgroundColor: "#04A766  !important",
                  color: "#fff",
                  fontSize: 16,
                  p: 2,
                  "&.Mui-disabled": { // Correctly targeting the disabled state
                    backgroundColor: "grey !important", // Change the background color when disabled
                    color: "#fff" // Optional: ensure the text color stays white when disabled
                  }
                }}
              >
                {loading ? "Signing in..." : "Sign in"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="end">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/forgot"
                  variant="button"
                  // color="info"
                  fontWeight="medium"
                  // textGradient
                  sx={{
                    color: "#04A766",
                  }}
                >
                  Forgot password ?
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
