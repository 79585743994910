import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

export const FileTypes = [
  { type: "pdf", value: "application/pdf" },
  { type: "xls", value: "application/vnd.oasis.opendocument.spreadsheet" },
  { type: "xlsx", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
  {
    type: "docx",
    value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { type: "doc", value: "application/msword" },
  { type: "png", value: "image/png" },
  { type: "jpg", value: "image/jpg" },
  { type: "jpeg", value: "image/jpg" },
];

export const getFileContentType = (extention) =>
  FileTypes.filter((ft) => ft.type === extention)[0].value;

// export const ScrollTop = () => {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "smooth",
//     });
//   }, [pathname]);
// };

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify({ ...obj }));
};

export const isEmptyObject = (obj) => {
  return JSON.stringify(obj) === "{}";
};

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const formatTime = (time) => {
  const parsedTime = moment(time, ["H:mm", "HH:mm"], true);
  if (parsedTime.format("H:mm") === time) {
    return parsedTime.format("HH:mm");
  } else {
    return time;
  }
};

export const convert24to12 = (time) => {
  // Extract hours and minutes from the time string
  const hours = parseInt(time.slice(0, 2));
  const minutes = time.slice(3, 5);

  // Determine AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  // const twelveHourTime = `${(hours % 12) || 12}:${minutes} ${amOrPm}`;

  return {
    time: `${hours % 12 || 12}:${minutes}`,
    ampm: amOrPm,
  };
};

export const getAgefromDate = (date) => {
  const dob = new Date(date);
  const today = new Date();
  const age = today.getFullYear() - dob.getFullYear();
  return age;
};

export const getAPIUrl = (endpoint) => process.env.REACT_APP_API_BASE_URL + endpoint;

export const getUserToken = () => {
  const userAuthToken = secureLocalStorage.getItem("authToken");
  return userAuthToken;
};

export const getuserid = () => {
  const user = {}; //Pull from redux store
  return user?.userid;
};

export const getUserName = () => {
  const user = {}; //Pull from redux store
  return user?.name;
};

export const clearUserData = () => {
  secureLocalStorage.clear();
};

export const capitalzationLabel = (label) => {
  if (label) {
    const output = `${label.charAt(0).toUpperCase()}${label.substr(1).toLowerCase()}`;
    return output;
  } else {
    return;
  }
};

export const upperCaseFieldValue = (stringValue) => {
  if (!stringValue) {
    return "";
  } else {
    return stringValue.toUpperCase().trim();
  }
};

export const swalWithBootstrapButtons = () =>
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger me-3",
      title: "custom-title",
    },
    buttonsStyling: false,
  });

export const capitalizeFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};
