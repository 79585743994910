import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import userIcon from "../../../src/assets/images/userIcon.svg";
import deleteImg from "../../../src/assets/images/Delete.svg";
import activateImge from "../../../src/assets/images/activate.svg";
import deactivateImage from "../../../src/assets/images/deactivate.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DeleteUser, GetAllUser, getDriverAsync, UserStatus } from "../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import UserDetailModal from "components/Modal/userDetailModal";
import DeleteModal from "components/Modal/deleteModal";
import SearchIcon from "@mui/icons-material/Search";
import { Search } from "layouts/styles.js";
import { SearchIconWrapper } from "layouts/styles.js";
import { StyledInputBase } from "layouts/styles.js";
import { TableHeadStyle } from "layouts/styles.js";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";


const Drivers = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [subType, setSubType] = useState("");
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortOrderActive, setSortOrderActive] = useState("");

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setSortOrderActive("");
  };

  const handleSortActiveChange = (event) => {
    setSortOrderActive(event.target.value);
    setSortOrder("");
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const getAllUser = () => {
    const sortValue = sortOrder || sortOrderActive || "";
    setLoading(true);
    let params = {
      roleId: "3",
      page: currentPage, // Pass the current page
      limit: 10, // Specify how many items per page
      statusSearch: subType,
      search: search,
      sort: sortValue, // Specify how many items per page
    };
    dispatch(GetAllUser(params))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj?.data, "objectAplication");
        setUserList(obj?.data?.payload?.data);
        setTotalPage(obj?.data?.payload?.total_pages);
      })
      .catch((obj) => {
        console.log("err0r");
      })
      .finally(() => setLoading(false));
  };

  const UserStatusUpdate = (id, status) => {
    let params = {
      is_active: status,
      id: id,
    };

    console.log(params, "paramssss");
    dispatch(UserStatus(params))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj?.data, "objectAplication");
        getAllUser();
      })
      .catch((obj) => {
        console.log("err0r");
      });
  };

  const handleDelete = async () => {
    await dispatch(DeleteUser(userId));
    await getAllUser();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page and fetch the users for that page
  };
  const getUserDetail = async (id) => {
    dispatch(getDriverAsync(id))
      .then(unwrapResult)
      .then((obj) => {
        setViewOpen(true);
        setUserDetail(obj?.data?.payload);
      })
      .catch((obj) => {});
  };

  useEffect(() => {
    getAllUser();
  }, [currentPage, subType, search, sortOrder, sortOrderActive]);

  return (
    <>
      <UserDetailModal
        open={viewOpen}
        setOpen={setViewOpen}
        userTitle="Driver Detail"
        userDetail={userDetail}
      />
      <DeleteModal open={open} setOpen={setOpen} deleteUser={handleDelete} />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  borderRadius="lg"
                  sx={{
                    color: "#ffffff",
                    background:
                      "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                   Driver Table
                  </MDTypography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearch}
                      />
                    </Search>
                    <Box className="mask-box">
                      <Box
                        className="mask"
                        style={{
                          transform: `translateX(${subType === "" ? "0" : subType === "1" ? "100px" : "200px"})`,
                        }}
                      />
                      <Button
                        disableRipple
                        variant="text"
                        onClick={() => setSubType("")}
                      >
                        All
                      </Button>
                      <Button
                        disableRipple
                        variant="text"
                        onClick={() => setSubType("1")}
                      >
                        Activate
                      </Button>
                      <Button
                        disableRipple
                        variant="text"
                        onClick={() => setSubType("0")}
                      >
                        Deactivate
                      </Button>
                    </Box>
                  </Box>
                </MDBox>
                <MDBox pt={3}>
                  <TableContainer
                    sx={{ boxShadow: "none", paddingBottom: "24px" }}
                  >
                    <Table>
                      <MDBox component="thead">
                        <TableRow>
                          <MDBox
                            component="th"
                            width="auto"
                            py={1.5}
                            px={3}
                            sx={{
                              borderBottom: "1px solid #f0f2f5",
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <MDBox sx={{ ...TableHeadStyle }}>Owner Name</MDBox>
                            <FormControl
                              sx={{
                                minWidth: "75px",
                                borderRadius: "4px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #ccc", // Ensure the border styling remains consistent
                                },
                                "& .MuiSelect-icon": {
                                  color: "#000", // Ensure the dropdown arrow is visible
                                  display: "block",
                                },
                              }}
                            >
                              <Select
                                value={sortOrder}
                                onChange={handleSortChange}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Select sort order",
                                }} // Accessibility
                                sx={{
                                  fontSize: "0.875rem",
                                  height: "30px",
                                  textAlign: "left", // Align text to the left
                                  "& .MuiSelect-select": {
                                    padding: "0 !important", // Override default padding
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                }}
                              >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value="1">A to Z</MenuItem>
                                <MenuItem value="2">Z to A</MenuItem>
                              </Select>
                            </FormControl>
                          </MDBox>
                          <MDBox
                            component="th"
                            width="auto"
                            py={1.5}
                            px={3}
                            sx={{
                              borderBottom: "1px solid #f0f2f5",
                            }}
                          >
                            <MDBox sx={{ ...TableHeadStyle }}>
                              {" "}
                              Register Date
                            </MDBox>
                          </MDBox>

                          <MDBox
                            component="th"
                            width="auto"
                            py={1.5}
                            px={3}
                            sx={{
                              borderBottom: "1px solid #f0f2f5",
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <MDBox sx={{ ...TableHeadStyle }}>Status</MDBox>
                            <FormControl
                              sx={{
                                minWidth: "75px",
                                borderRadius: "4px",

                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #ccc", // Ensure the border styling remains consistent
                                },
                                "& .MuiSelect-icon": {
                                  color: "#000", // Ensure the dropdown arrow is visible
                                  display: "block",
                                },
                              }}
                            >
                              <Select
                                value={sortOrderActive}
                                onChange={handleSortActiveChange}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Select sort order",
                                }} // Accessibility
                                sx={{
                                  fontSize: "0.875rem",
                                  height: "30px",
                                  textAlign: "left", // Align text to the left
                                  "& .MuiSelect-select": {
                                    padding: "0 !important", // Override default padding
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                }}
                              >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value="3">A to Z</MenuItem>
                                <MenuItem value="4">Z to A</MenuItem>
                              </Select>
                            </FormControl>
                          </MDBox>
                          <MDBox
                            component="th"
                            width="auto"
                            py={1.5}
                            px={3}
                            sx={{
                              borderBottom: "1px solid #f0f2f5",
                            }}
                          >
                            <MDBox sx={{ ...TableHeadStyle }}>Action</MDBox>
                          </MDBox>

                          <MDBox
                            component="th"
                            width="auto"
                            py={1.5}
                            px={3}
                            sx={{
                              borderBottom: "1px solid #f0f2f5",
                            }}
                          >
                            <MDBox
                              sx={{ ...TableHeadStyle, textAlign: "center" }}
                            >
                              View Details
                            </MDBox>
                          </MDBox>
                        </TableRow>
                      </MDBox>
                      {loading ? (
                        <>
                          <TableBody>
                            <TableRow>
                              <MDBox
                                component="td"
                                py={1.5}
                                px={3}
                                sx={{
                                  textAlign: "center",
                                }}
                                colspan="4"
                              >
                                <CircularProgress
                                  size={30}
                                  sx={{
                                    color: "grey",
                                    marginTop: "12px",
                                  }}
                                />
                              </MDBox>
                            </TableRow>
                          </TableBody>
                        </>
                      ) : (
                        <TableBody>
                          {userList && userList.length > 0 ? (
                            userList?.map((user) => (
                              <TableRow key={user.id}>
                                <MDBox
                                  component="td"
                                  width="25%"
                                  py={1.5}
                                  px={3}
                                  sx={{
                                    borderBottom: "1px solid #f0f2f5",
                                  }}
                                >
                                  <MDBox
                                    sx={{
                                      ...TableHeadStyle,
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "6px",
                                    }}
                                  >
                                    <img
                                      src={
                                        user?.profile_image
                                          ? user?.profile_image
                                          : userIcon
                                      }
                                      alt="carimg"
                                      style={{
                                        width: "28px",
                                        height: "28px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: "#344767",
                                        fontSize: "0.875rem",
                                        fontWeight: "600",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {user.full_name || "N/A"}
                                    </Typography>
                                  </MDBox>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  py={1.5}
                                  px={3}
                                  sx={{
                                    borderBottom: "1px solid #f0f2f5",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {moment(user.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Typography>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  py={1.5}
                                  px={3}
                                  sx={{
                                    borderBottom: "1px solid #f0f2f5",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      background:
                                        user?.is_active === 1
                                          ? "linear-gradient(195deg, #66BB6A, #43A047)"
                                          : "linear-gradient(195deg, #42424a, #191919)",
                                      border: "none",
                                      borderRadius: "0.375rem",
                                      color: "#ffffff !important",
                                      padding: "0.55em 0.9em",
                                      fontWeight: "700",
                                      fontSize: "0.5625rem",
                                      minHeight: "1.8rem",
                                      width: "85px",
                                    }}
                                  >
                                    {user?.is_active === 1
                                      ? "Activate"
                                      : "Deactivate"}
                                  </Button>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  py={1.5}
                                  px={3}
                                  sx={{
                                    borderBottom: "1px solid #f0f2f5",
                                  }}
                                >
                                  <Box>
                                    <Tooltip
                                      title={
                                        user?.is_active === 1
                                          ? "Deactivate User"
                                          : "Activate User"
                                      }
                                      placement="top"
                                    >
                                      <img
                                        src={
                                          user?.is_active === 1
                                            ? deactivateImage
                                            : activateImge
                                        }
                                        alt="activateImge"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          cursor: "pointer",
                                          marginRight: "6px",
                                        }}
                                        onClick={() =>
                                          UserStatusUpdate(
                                            user?.id,
                                            user?.is_active === 1 ? "0" : "1"
                                          )
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title="Delete User"
                                      placement="top"
                                    >
                                      <img
                                        src={deleteImg}
                                        alt="deleteimg"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setOpen(true);
                                          setUserId(user?.id);
                                        }}
                                      />
                                    </Tooltip>
                                  </Box>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  py={1.5}
                                  px={3}
                                  sx={{
                                    borderBottom: "1px solid #f0f2f5",
                                    textAlign: "center",
                                  }}
                                >
                                  <RemoveRedEyeIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      getUserDetail(user?.id);
                                    }}
                                  />
                                </MDBox>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <MDBox
                                component="td"
                                py={1.5}
                                px={3}
                                sx={{
                                  borderBottom: "1px solid #f0f2f5",
                                  textAlign: "center",
                                }}
                                colspan={5}
                              >
                                <Typography>No data found</Typography>
                              </MDBox>
                            </TableRow>
                          )}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </MDBox>
              </Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  alignItems: "end",
                }}
              >
                <Pagination
                  count={totalPage} // Total pages based on user data
                  page={currentPage} // Current page
                  onChange={handlePageChange} // Handle page change
                  color="grey" // You can change the color if needed
                  // variant="outlined"
                  shape="rounded"
                  sx={{
                    "& .css-1eaa915-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                      {},
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Drivers;
