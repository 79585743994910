import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
import { useMaterialUIController } from "context";
import { Box } from "@mui/material";
import { GetPendingProfile, selectUserProfile } from "../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

function SidenavCollapse({ icon, name, active, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const userProfile = useSelector(selectUserProfile);
  const userProfileData = userProfile?.data?.payload?.data

  return (
    <>
      {
        name === "Users Profile" ?
          (
            <ListItem component="li">
              <MDBox
                {...rest}
                sx={(theme) =>
                  collapseItem(theme, {
                    active,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <ListItemIcon
                  sx={(theme) =>
                    collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                  }
                >
                  {typeof icon === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={(theme) =>
                    collapseText(theme, {
                      miniSidenav,
                      transparentSidenav,
                      whiteSidenav,
                      active,
                    })
                  }
                />
                {userProfileData && userProfileData.length > 0 &&
                  <Box sx={{
                    width: "8px",
                    height: "8px",
                    display: "block",
                    background: "#fff",
                    borderRadius: "50%"
                  }}>
                  </Box>
                }
              </MDBox>
            </ListItem>
          )
          :
          (
            <ListItem component="li">
              <MDBox
                {...rest}
                sx={(theme) =>
                  collapseItem(theme, {
                    active,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <ListItemIcon
                  sx={(theme) =>
                    collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                  }
                >
                  {typeof icon === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={(theme) =>
                    collapseText(theme, {
                      miniSidenav,
                      transparentSidenav,
                      whiteSidenav,
                      active,
                    })
                  }
                />
              </MDBox>
            </ListItem>
          )
      }

    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
