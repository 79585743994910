import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import { yupResolver } from "@hookform/resolvers/yup";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import MDButton from "components/MDButton";
import PhoneInput from "react-phone-input-2"; // Import phone input library
import "react-phone-input-2/lib/style.css"; // Import styles for phone input
import { useDispatch } from "react-redux";
import { AddTowingasync, EditTowingasync } from "../../redux/user/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import mapboxgl from "mapbox-gl"; // Import Mapbox GL
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
mapboxgl.accessToken =
  "sk.eyJ1Ijoibm93YWhhYWxhIiwiYSI6ImNtMml4YzhtcTB0OGcyaXNkY3d6MDBkMGoifQ.zRrqd337-2UQTACvn9jV5Q";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 630,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  border: "none !important",
};

const validationPatterns = {
  phone: /^\+?[1-9]\d{1,14}$/, // Regex pattern for phone numbers
};

const phoneSchema = yup
  .string()
  .required("Phone number is required")
  .matches(validationPatterns.phone, "Invalid Phone Number")
  .min(10, "Phone number must be at least 10 digits long")
  .max(15, "Phone number cannot be more than 15 digits long");

const getSchema = () =>
  yup.object().shape({
    companyName: yup.string().required("Company name is required"),
    location: yup.string().required("Location is required"),
    currency: yup.string().required("Currency is required"),
    phone: phoneSchema, // Always require phone validation
    price: yup
      .number()
      .typeError("Price must be a number")
      .required("Price is required"),
    range: yup
      .number()
      .typeError("Range must be a number")
      .required("Range is required"),
  });

const AddTowing = (props) => {
  const {
    open,
    setOpen,
    getAllTowings,
    editCompany,
    setEditCompany,
    getCurrency,
  } = props;
  const phoneInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [phoneCode, setPhoneCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [latitude, setLatitude] = useState(editCompany?.lattitude || null);
  const [longitude, setLongitude] = useState(editCompany?.longitude || null);
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]); // State for suggestions
  const [selectedCurrency, setSelectedCurrency] = useState("United States ($)");
  console.log(selectedCurrency, "selectedCurrency");

  const currentDate = Date.now();

  // Handle close with reset
  const handleClose = () => {
    reset(); // Reset the form fields
    setOpen(false); // Close the modal
    setEditCompany(null);
    setSelectedCurrency("United States ($)");
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema()),
    defaultValues: {
      companyName: "",
      location: "",
      phone: "",
      price: "",
      currency:"United States ($)",
      range: "",
    },
  });

  console.log(watch("currency"),"watchwatch");
  

  const onChangePhoneNumber = (value, data) => {
    const phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    phoneNumber = phoneNumber.startsWith("0")
      ? phoneNumber.substring(1)
      : phoneNumber;

    setValue("phone", `${phoneCode}${phoneNumber}`);
    setPhoneCode(phoneCode);
    setPhoneNumber(phoneNumber);
  };

  //  Type Select OnChange
  const handleSelectChange = (e) => {
    setValue("currency", e.target.value);
    setSelectedCurrency(e.target.value);
  };

  // onSave for towing
  const onSave = (data) => {
    setLoading(true);
    if (editCompany) {
      const params = {
        id: editCompany?.id,
        company_name: data.companyName,
        phone_no: phoneNumber,
        currency_symbol: selectedCurrency,
        dial_code: `+${phoneCode}`,
        location: data.location,
        towing_charges: data.price.toString(),
        lattitude: latitude !== null ? latitude.toString() : "", // Check for null
        longitude: longitude !== null ? longitude.toString() : "", // Check for null
        range: data.range.toString(),
      };
      dispatch(EditTowingasync(params))
        .then(unwrapResult)
        .then((response) => {
          toast.success("Edit Towing successfully!");
          getAllTowings();
          handleClose(); // Optionally close modal after success
        })
        .catch((error) => {
          toast.error(error?.msg);
        })
        .finally(() => setLoading(false));
    } else {
      const params = {
        company_name: data.companyName,
        phone_no: phoneNumber,
        dial_code: `+${phoneCode}`,
        location: data.location,
        currency_symbol: selectedCurrency,
        towing_charges: data.price.toString(),
        lattitude: latitude !== null ? latitude.toString() : "", // Check for null
        longitude: longitude !== null ? longitude.toString() : "", // Check for null
        range: data.range.toString(),
      };
      dispatch(AddTowingasync(params))
        .then(unwrapResult)
        .then((response) => {
          toast.success("Add Towing successfully!");
          getAllTowings();
          setSelectedCurrency("");
          handleClose(); // Optionally close modal after success
        })
        .catch((error) => {
          toast.error(error?.msg);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleLocationChange = async (event) => {
    const location = event.target.value;
    setValue("location", location);

    if (location) {
      const query = encodeURIComponent(location);
      const url = `https://api.mapbox.com/search/searchbox/v1/suggest?q=${query}&limit=10&language=en&session_token=${currentDate.millisecondsSinceEpoch}&access_token=${mapboxgl.accessToken}`;

      const response = await fetch(url);
      const data = await response.json();
      if (data?.suggestions?.length > 0) {
        setSuggestions(data?.suggestions);
      } else {
        setSuggestions([]);
      }
    } else {
      setLatitude(null);
      setLongitude(null);
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = async (feature) => {
    const id = feature.mapbox_id;
    const url = `https://api.mapbox.com/search/searchbox/v1/retrieve/${id}?session_token=${currentDate.millisecondsSinceEpoch}&access_token=${mapboxgl.accessToken}`;
    setSuggestions([]);
    console.log(feature, "feature");
    setValue("location", ` ${feature?.full_address || ""} ,${feature?.name}`);

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Check if the response has features and extract coordinates if available
      if (data.features && data.features.length > 0) {
        const featureData = data.features[0]; // Access the first feature in the array
        const coordinates = featureData.geometry.coordinates;

        setLongitude(coordinates[0]);
        setLatitude(coordinates[1]);
        setValue(
          "location",
          feature.properties.full_address || feature.properties.name
        );
        setSuggestions([]);
      } else {
        console.warn("No features found in the response.");
      }
    } catch (error) {
      console.error("Error retrieving location details:", error);
    }
  };

  useEffect(() => {
    if (editCompany) {
      reset({
        companyName: editCompany?.company_name,
        location: editCompany?.location || "",
        phone: `${editCompany?.dial_code}${editCompany?.phone_no}`,
        price: editCompany?.towing_charges || "",
        currency: editCompany?.currency_symbol || "",
        range:editCompany?.range || ""
      });
      setSelectedCurrency(editCompany?.currency_symbol || "");
      setPhoneCode(editCompany.dial_code.replace("+", "")); // Set phone code and number correctly
      setPhoneNumber(editCompany.phone_no);
    } else {
      // Reset form to default values for "Add" mode
      reset({
        companyName: "",
        location: "",
        phone: "",
        price: "",
        currency: "United States ($)",
        range: "",
      });
      setPhoneCode(null);
      setPhoneNumber(null);
    }
  }, [editCompany, reset]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
          onClick={handleClose}
        >
          <CancelIcon />
        </IconButton>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            m: 2,
            borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
            paddingBottom: "16px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            {editCompany ? "Edit Towing" : "Add Towing"}
          </Typography>
        </Box>
        <MDBox component="form" role="form" onSubmit={handleSubmit(onSave)}>
          {/* Company Name Field */}
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Company Name"
              fullWidth
              {...register("companyName")}
            />
            {errors.companyName && (
              <Typography sx={{ fontSize: 10, color: "red" }}>
                {errors.companyName?.message}
              </Typography>
            )}
          </MDBox>

          {/* Location Field */}
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Location"
              fullWidth
              {...register("location")}
              onChange={handleLocationChange}
            />
            {errors.location && (
              <Typography sx={{ fontSize: 10, color: "red" }}>
                {errors.location?.message}
              </Typography>
            )}
          </MDBox>

          {/* Suggestions List */}
          {suggestions.length > 0 && (
            <Box
              sx={{
                maxHeight: "200px",
                overflowY: "auto",
                border: "1px solid #ccc",
                borderRadius: "4px",
                zIndex: 1000,
                position: "absolute",
                backgroundColor: "#fff",
                width: "100%",
                maxWidth: "565px",
              }}
            >
              {suggestions.map((feature, index) => (
                <Box
                  key={index}
                  onClick={() => handleSuggestionClick(feature)}
                  sx={{
                    padding: "8px",
                    paddingBottom: "0 !important",
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                >
                  {/* Displaying the full_address or name of the location */}
                  <Typography
                    sx={{
                      fontSize: "0.86rem !important",
                    }}
                  >{`${feature.full_address || ""} , ${feature.name}`}</Typography>
                </Box>
              ))}
            </Box>
          )}
          {/* Phone Field */}
          <MDBox mb={2}>
            <PhoneInput
              ref={phoneInputRef}
              country={"gb"} // Set to Uk only
              value={watch("phone")} // Bind to form state
              onChange={onChangePhoneNumber} // Set phone value on change
              placeholder="Phone number"
              inputStyle={{
                width: "100%",
                height: "52px",
                fontSize: "14px",
                borderRadius: "8px",
                borderColor: "#d1d3e2",
              }}
            />
            {errors.phone && (
              <Typography sx={{ fontSize: 10, color: "red" }}>
                {errors.phone?.message}
              </Typography>
            )}
          </MDBox>
          <MDBox mb={2}>
            <div className="col-md-6">
              <div className="formInput_Parent token_SelectParent">
                {/* <ExpandMoreIcon /> */}
                <FormControl fullWidth>
                  <Select
                    //  IconComponent={<ExpandMoreIcon/>}
                    IconComponent={() => (
                      <ExpandMoreIcon
                        sx={{
                          "&.MuiSvgIcon-root": {
                            color: "#000", // Customize the icon color
                            backgroundColor: "#fff",
                            height: "30px",
                            width: "30px",
                            position: "absolute",
                            zIndex: "-1",
                            right: "6px",
                          },
                        }}
                      />
                    )}
                    // labelId="token-type-label"
                    className="customSelect"
                    name="type"
                    value={selectedCurrency}
                    // displayEmpty
                    onChange={handleSelectChange}
                    // displayEmpty
                    sx={{
                      fontFamily: "sans-serif",
                      "&.MuiSvgIcon-root": {
                        color: "#fff", // Customize the icon color
                        backgroundColor: "#282545e3",
                      },
                      fontStyle: "normal",
                      color: selectedCurrency === "" ? "#FFFFFF20" : "#FFF",
                      // border: "1px solid #d2d6da",
                      borderRadius: "0.375rem",
                      width: "100%",
                      height: "52px",
                      fontSize: "14px",
                      // borderColor: "#d1d3e2",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "6px",
                          border: "1px solid #FFFFFF4D",
                          background: "#100e1f",
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginTop: "5px",
                          "&.MuiPaper-root ": {
                            height: "120px",
                          },

                          "& .MuiMenuItem-root": {
                            color: "#344767",
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "inherit",
                            "&.Mui-selected": {
                              backgroundColor: "#f0f0f0",
                              color: "#344767",
                            },
                            "&:hover": {
                              backgroundColor: "#f0f0f0",
                              color: "#344767",
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: "#f0f0f0",
                            },
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Currency
                    </MenuItem>

                    {getCurrency?.map((item) => (
                      <MenuItem
                        key={item?.name}
                        value={`${item?.name} (${item?.currency_symbol})`}
                      >
                        {`${item?.name} (${item?.currency})`}
                      </MenuItem>
                    ))}

                    {/* <MenuItem value="1">Promotional</MenuItem> */}
                  </Select>
                  <input
                    type="hidden"
                    {...register("currency")}
                    value={selectedCurrency}
                  />
                </FormControl>
              </div>

              {errors.currency && (
                <Typography sx={{ fontSize: 10, color: "red" }}>
                  {errors.currency?.message}
                </Typography>
              )}
            </div>
          </MDBox>
          {/* Price Field */}
          <MDBox mb={2}>
            <MDInput
              type="number"
              label="Price"
              placeholder=""
              fullWidth
              {...register("price")}
            />
            {errors.price && (
              <Typography sx={{ fontSize: 10, color: "red" }}>
                {errors.price?.message}
              </Typography>
            )}
          </MDBox>

          {/* Range Field */}
          <MDBox mb={2}>
            <MDInput
              type="number"
              label="Range"
              placeholder=""
              fullWidth
              {...register("range")}
              InputLabelProps={{
                sx: {
                  overflow: "visible", // Ensures the label is not clipped
                },
              }}
            />
            {errors.range && (
              <Typography sx={{ fontSize: 10, color: "red" }}>
                {errors.range?.message}
              </Typography>
            )}
          </MDBox>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MDButton
              type="submit"
              variant="gradient"
              disabled={loading}
              sx={{
                color: "#ffffff",
                background:
                  "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%) !important",
                marginTop: "24px",
                textTransform: "capitalize",
                fontSize: 14,
                padding: "14px 34px",
                width: "100%",
                maxWidth: "160px",
                "&.Mui-disabled": {
                  // Correctly targeting the disabled state
                  backgroundColor: "grey !important", // Change the background color when disabled
                  color: "#fff", // Optional: ensure the text color stays white when disabled
                },
              }}
            >
              {loading ? "Saving..." : "Save"}
            </MDButton>
          </Box>
        </MDBox>
      </Box>
    </Modal>
  );
};

export default AddTowing;
