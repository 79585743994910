import {  useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Box, Typography } from "@mui/material";
import verifyImg from "../../../../src/assets/images/verifyImg.png";
import authBg from "../../../../src/assets/images/authBg.png"



function Verify() {
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state;

    const handleGotItBtn = () => {
        navigate('/')
    }


    return (
        <BasicLayout image={authBg}>
            <Card>
                <Box
                    mx={2}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <img src={verifyImg} />
                </Box>
                <Typography sx={{
                    textAlign: "center",
                    color: "#00142D",
                    fontSize: "24px",
                    fontWeight: "700",
                    textTransform: "capitalize"
                }}
                >
                    Check your email
                </Typography>
                <Typography sx={{
                    textAlign: "center",
                    color: "rgba(0, 20, 45, 0.5)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "capitalize"
                }}>
                    We sent a password recovery instructions to your email.
                    <span style={{
                        fontWeight: "600",
                        color: "#00142D",
                    }}
                    >{email}</span>
                </Typography>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox>
                        <MDBox  mb={1} sx={{textAlign:"center"}}>
                            <MDButton
                                variant="gradient"
                                sx={{
                                    backgroundColor: "#04A766  !important",
                                    color: "#fff",
                                    fontSize:16,
                                    padding:"14px 50px !important"
                                }}
                                onClick={handleGotItBtn}
                            >
                                Got It
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Verify;
