import { ApiClient } from "../utilities/api";
import ApiPath from "../constants/apiPath";

export function loginApi(data) {
  const url = ApiPath.AuthApiPath.LOGIN;
  return ApiClient.post(url, data);
}

export function forgotPasswordApi(data) {
  const url = ApiPath.AuthApiPath.FORGOT_PASSWORD;
  return ApiClient.post(url, data);
}

export function resetPasswordApi(data) {
  const url = ApiPath.AuthApiPath.RESET_PASSWORD;
  return ApiClient.post(url, data);
}
