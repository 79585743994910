const ApiPath = {
  AuthApiPath: {
    LOGIN: "/api/v1/users/admin/login",
    FORGOT_PASSWORD: "/api/v1/users/forgot/password",
    RESET_PASSWORD: "/api/v1/users/reset/password",
  },
  UserApiPath: {
    GET_ALL_USER: "api/v1/users/all",
    UPDATE_USER_STATUS: "/api/v1/users/activate/deactivate/",
    GET_TOTAL_COUNT: "/api/v1/users/admin/home",
    DELETE_USER: "/api/v1/users/",
    PENDING_REQUEST: "/api/v1/users/pending/requests",
    UPDATE_PROFILE_STATUS: "/api/v1/users/approve/reject/profile/request",
    ADD_TOWING: "/api/v1/towing_companies",
    GET_TOWING: "/api/v1/towing_companies",
    DELETE_TOW: "/api/v1/towing_companies/",
    EDIT_TOWING:"/api/v1/towing_companies",
    GET_ALL_CURRENCY:"/api/v1/users/countries",
    GET_USER:"/api/v1/users/details/",
    GET_DRIVER:"/api/v1//users/driver/details/"
  },
};

export default ApiPath;
