const screens = {
  AUTH: {
    LOGIN: "/",
    FORGOT_PASSWORD: "/forgot",
    RESET_PASSWORD: "/new-password",
    VERIFY: "/verify",
  },
  HOME: {
    HOME: "dashboard",
    USER: "user",
    CAR_OWNER: "car-owner",
    DRIVER: "driver",
  },
};
export default screens;
