// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
    min-height: auto !important;
    font-size: 16px;
}
.Toastify__toast-body {
    padding: 4px !important;
} 

.mask-box {
    border-radius: 20px;
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 1px 1px 2px rgb(165 165 165 / 22%) inset;
}
  .mask-box .mask {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background: #2b9179;
    position: absolute;
    box-shadow: 1px 0 2px #05a1ab00;
    transition: all 0.5s ease;
}
  .mask-box .MuiButton-root {
    border-radius: 20px;
    width: 100px;
    height: 40px;
    font-weight: bold;
    transition: all 0.2s 0.1s ease;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 1rem;
    font-weight: 400;
  }
  .mask-box .MuiButton-root:hover {
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/public/custom.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,qCAAqC;IACrC,oDAAoD;AACxD;EACE;IACE,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,+BAA+B;IAC/B,yBAAyB;AAC7B;EACE;IACE,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;IAC9B,sBAAsB;IACtB,qCAAqC;IACrC,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,YAAY;EACd","sourcesContent":[".Toastify__toast {\n    min-height: auto !important;\n    font-size: 16px;\n}\n.Toastify__toast-body {\n    padding: 4px !important;\n} \n\n.mask-box {\n    border-radius: 20px;\n    position: relative;\n    background: rgba(255, 255, 255, 0.15);\n    box-shadow: 1px 1px 2px rgb(165 165 165 / 22%) inset;\n}\n  .mask-box .mask {\n    width: 100px;\n    height: 40px;\n    border-radius: 20px;\n    background: #2b9179;\n    position: absolute;\n    box-shadow: 1px 0 2px #05a1ab00;\n    transition: all 0.5s ease;\n}\n  .mask-box .MuiButton-root {\n    border-radius: 20px;\n    width: 100px;\n    height: 40px;\n    font-weight: bold;\n    transition: all 0.2s 0.1s ease;\n    color: #fff !important;\n    text-transform: capitalize !important;\n    font-size: 1rem;\n    font-weight: 400;\n  }\n  .mask-box .MuiButton-root:hover {\n    opacity: 0.8;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
