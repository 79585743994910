// styles.js
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Box, Button, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  height: "2.1em !important",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const TableHeadStyle = {
  color: "#7b809a",
  fontSize: "0.75rem",
  fontWeight: "700",
  textTransform: "uppercase",
  textAlign: "left",
};

export const StatusButton = styled(Button)(({ theme, active }) => ({
  background: active
    ? "linear-gradient(195deg, #66BB6A, #43A047)"
    : "linear-gradient(195deg, #42424a, #191919)",
  border: "none",
  borderRadius: "0.375rem",
  color: "#ffffff !important",
  padding: "0.55em 0.9em",
  fontWeight: "700",
  fontSize: "0.5625rem",
  minHeight: "1.8rem",
  width: "85px",
}));

export const TableCellStyle = {
  py: 1.5,
  px: 3,
  borderBottom: "1px solid #f0f2f5",
};

export const TableRowActionStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "6px",
};

export const NoDataStyle = {
  textAlign: "center",
  py: 1.5,
  px: 3,
  borderBottom: "1px solid #f0f2f5",
};
