import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";

// Styles for typography and card
const headingStyle = {
  color: "#000000",
  fontSize: "16px",
  fontWeight: 700,
  textAlign: "left",
  marginBottom: "2px",
};

const subheadingStyle = {
  color: "#555555",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
};

const cardStyle = {
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
  minHeight: "300px", // Set a max height to enable scrolling
  overflow: "auto", // Make the content inside scrollable
  marginBottom: "20px",
};

const VehicleRegister = ({ vehicleData }) => {
  if (!vehicleData || vehicleData.length === 0) {
    return (
      <Box
        sx={{
          background: "#f5f5f5",
          padding: "24px",
          borderRadius: "8px",
          margin: "0 auto",
          height: "calc(52vh - 100px)",
          overflowY: "auto", // Enable vertical scrolling for the main container
          display:"flex",
          alignItems:"center",
          justifyContent:"center"
        }}
      >
        <Typography sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}>
          No Vehicle Details Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        background: "#f5f5f5",
        padding: "24px",
        borderRadius: "8px",
        margin: "0 auto",
        height: "calc(52vh - 100px)",
        overflowY: "auto", // Enable vertical scrolling for the main container
      }}
    >
      {vehicleData.map((vehicle) => (
        <Paper key={vehicle.id} sx={cardStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Vehicle Name:</Typography>
              <Typography sx={subheadingStyle}>{vehicle.model}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Brand Name:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.car_brand_details?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Registration Year:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.registration_year}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Vehicle Number:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.vehicle_number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Engine Number:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.engine_number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Chassis Number:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.chassis_number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Vehicle Color:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.vehicle_color}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Engine Capacity:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.engine_capacity_details.text}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Mileage:</Typography>
              <Typography sx={subheadingStyle}>
                {vehicle.millage_details.text}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default VehicleRegister;
