import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Box, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import authLogo from "../../../../src/assets/images/authLogo.png"
import authBg from "../../../../src/assets/images/authBg.png"
import { resetPasswordApi } from "controller/auth";
import screens from "constants/Screens";
import { toast } from "react-toastify";

function ResetPassword() {
    const navigate = useNavigate();
    const [searchParam] = useSearchParams()
    const id = searchParam.get("q")

    const schema = yup.object().shape({
        new_password: yup
            .string()
            .required("This is a required field"),
        confirm_password: yup.string()
            .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
    });
    const [IsLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSave = async (data) => {
        console.log(onSave, "ONSAVEVVVVV")
        const param = {
            ...data,
            "user_id": id
        }
        setIsLoading(true)
        try {
            const { data } = await resetPasswordApi(param)
            navigate(screens.AUTH.LOGIN, { replace: true })
        } catch (error) {
            toast.error(error.msg)
        } finally {
            setIsLoading(false)
            reset()
        }
    };

    return (
        <BasicLayout image={authBg}>
            <Card>
                <Box
                    mx={2}
                    mt={-5}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <img src={authLogo} />
                </Box>
                <Typography sx={{
                    textAlign: "left",
                    color: "#00142D",
                    fontSize: "24px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    marginLeft: 3
                }}
                >
                    Reset Password
                </Typography>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form" onSubmit={handleSubmit(onSave)}>
                        <MDBox mb={2}>
                            <MDInput type="password" label="New Password" fullWidth  {...register("new_password")} />
                            {errors.new_password && <Typography
                                sx={{
                                    fontSize: 10,
                                    color: "red"
                                }}
                            >{errors.new_password?.message}</Typography>}
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" label="Confirm Password" fullWidth  {...register("confirm_password")} />
                            {errors.confirm_password && <Typography
                                sx={{
                                    fontSize: 10,
                                    color: "red"
                                }}
                            >{errors.confirm_password?.message}</Typography>}
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton
                                variant="gradient"
                                // color="info"
                                fullWidth
                                type="submit"
                                disabled={IsLoading} // Disable button while loading
                                sx={{
                                    backgroundColor: "#04A766  !important",
                                    color: "#fff",
                                    fontSize: 16,
                                    p: 2,
                                    "&.Mui-disabled": {
                                        backgroundColor: "grey !important",
                                        color: "#fff"
                                    }
                                }}
                            >
                                Continue
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default ResetPassword;
