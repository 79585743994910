import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import Icon from "@mui/material/Icon";
import ForgotPassWord from "layouts/authentication/forgotPassword";
import Verify from "layouts/authentication/verify";
import ResetPassword from "layouts/authentication/resetPassword";
import Cars from "layouts/cars";
import Company from "layouts/company";
import Drivers from "layouts/Driver";
import driverImg from "../src/assets/images/driver.svg";
import companyImg from "../src/assets/images/building.svg";
import carImg from "../src/assets/images/car.svg";
import UserProfile from "layouts/userprofile";
import towingImg from "../src/assets/images/towing.svg";
import Towing from "layouts/towing";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    auth: true,
    component: <Dashboard />,
  },
  {
    type: "title",
    key: "user-management-title",
    title: "User Management", // This will be displayed as a heading
  },
  {
    type: "divider", // This adds a divider under "User Management"
    key: "user-management-divider",
  },
  {
    type: "collapse",
    name: "Company",
    key: "user",
    icon: (
      <img
        src={companyImg}
        alt="Driver"
        style={{
          width: "24px",
          height: "24px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/user",
    auth: true,
    component: <Company />,
  },
  {
    type: "collapse",
    name: "Vehicle Owner",
    key: "vehicle-owner",
    icon: (
      <img
        src={carImg}
        alt="Driver"
        style={{
          width: "24px",
          height: "24px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ), // Use image for Driver,
    route: "/vehicle-owner",
    auth: true,
    component: <Cars />,
  },
  {
    type: "collapse",
    name: "Driver",
    key: "driver",
    icon: (
      <img
        src={driverImg}
        alt="Driver"
        style={{
          width: "24px",
          height: "24px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/driver",
    auth: true,
    component: <Drivers />,
  },
  {
    type: "divider", // This adds a divider under "User Management"
    key: "user-management-divider-1",
  },
  {
    type: "collapse",
    name: "Users Profile",
    key: "user-profile",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/user-profile",
    auth: true,
    component: <UserProfile />,
  },
  {
    type: "collapse",
    name: "Towing",
    key: "towing",
    icon: (
      <img
        src={towingImg}
        alt="Driver"
        style={{
          width: "28px",
          height: "28px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/towing",
    auth: true,
    component: <Towing />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    name: "forgot password",
    key: "forgot password",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/forgot",
    component: <ForgotPassWord />,
  },
  {
    name: "verify",
    key: "verify",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/verify",
    component: <Verify />,
  },
  {
    name: "reset password",
    key: "reset password",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/new-password",
    component: <ResetPassword />,
  },
];

export default routes;
