import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import moment from "moment";

// Styles for typography and card
const headingStyle = {
  color: "#000000",
  fontSize: "16px",
  fontWeight: 700,
  textAlign: "left",
  marginBottom: "4px",
};

const subheadingStyle = {
  color: "#555555",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
};

const cardStyle = {
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
  marginBottom: "20px",
};

const DriverCompany = ({ CompanyData }) => {
  if (!CompanyData || CompanyData.length === 0) {
    return (
      <Box
        sx={{
          background: "#f5f5f5",
          padding: "24px",
          borderRadius: "8px",
          margin: "0 auto",
          height: "calc(52vh - 100px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
        >
          No Driver Data Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        background: "#f5f5f5",
        padding: "24px",
        borderRadius: "8px",
        margin: "0 auto",
        height: "calc(52vh - 100px)",
        overflowY: "auto", // Enable vertical scrolling
      }}
    >
      {CompanyData.map((item) => (
        <Paper key={item.id} sx={cardStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Owner Name:</Typography>
              <Typography sx={subheadingStyle}>
                {item.company_owner_details?.full_name || "N/A"}
              </Typography>
            </Grid>
            {item.company_owner_details?.company_name && (
              <Grid item xs={12} sm={6}>
                <Typography sx={headingStyle}>Company Name:</Typography>
                <Typography sx={subheadingStyle}>
                  {item.company_owner_details?.company_name || "N/A"}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Joined Date:</Typography>
              <Typography sx={subheadingStyle}>
              {moment(item.joined_date).format("DD/MM/YYYY")} 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={headingStyle}>Phone Number:</Typography>
              <Typography sx={subheadingStyle}>
              {`${item.company_owner_details.country_code} ${item.company_owner_details.phone_no}`}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default DriverCompany;
