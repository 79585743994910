import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Box, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordApi } from "controller/auth";
import authLogo from "../../../../src/assets/images/authLogo.png"
import { toast } from "react-toastify";
import authBg from "../../../../src/assets/images/authBg.png"
import screens from "constants/Screens";



function ForgotPassWord() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().required("This is a required field"),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSave = async (data) => {
        setIsLoading(true);
        data.type = "email";
        console.log(data, "dataa");

        try {
            const res = await forgotPasswordApi(data);
            navigate(screens.AUTH.VERIFY, { state: data.email, replace: true });
        } catch (error) {
            toast.error(error.msg);
        } finally {
            reset();
            setIsLoading(false);
        }
    };

    return (
        <BasicLayout image={authBg}>
            <Card>
                <Box
                    mx={2}
                    mt={-5}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <img src={authLogo} />
                </Box>
                <Typography sx={{
                    textAlign: "center",
                    color: "#00142D",
                    fontSize: "24px",
                    fontWeight: "700",
                    textTransform: "capitalize"
                }}
                >
                    Forgot Password
                </Typography>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form" onSubmit={handleSubmit(onSave)}>
                        <MDBox mb={2}>
                            <MDInput type="email" label="Email" fullWidth {...register("email")} />
                            {errors.email && <Typography
                                sx={{
                                    fontSize: 10,
                                    color: "red"
                                }}
                            >
                                {errors.email?.message}</Typography>}
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton
                                variant="gradient"
                                // color="info"
                                fullWidth
                                type="submit"
                                disabled={isLoading} // Disable button while loading
                                sx={{
                                    backgroundColor: "#04A766  !important",
                                    color: "#fff",
                                    fontSize: 16,
                                    p: 2,
                                    "&.Mui-disabled": { 
                                        backgroundColor: "grey !important", 
                                        color: "#fff"
                                    }
                                }}
                            >
                                continue
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="end">
                            <MDTypography variant="button" color="text">
                                <MDTypography
                                    component={Link}
                                    to="/"
                                    variant="button"
                                    // color="info"
                                    fontWeight="medium"
                                    // textGradient
                                    sx={{
                                        color: "#04A766",
                                    }}
                                >
                                    Go back
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default ForgotPassWord;
