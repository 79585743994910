import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import { loginApi } from "../../controller/auth";
import { clearUserData } from "../../utilities/helper";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  loading: false,
};

export const loginAsync = createAsyncThunk("auth/loginApi", async (data) => {
  try {
    const response = await loginApi(data);
    secureLocalStorage.setItem("authToken", response?.data?.payload?.token);
    toast.success(response?.data?.msg);
    return response?.data?.payload?.user;
  } catch (error) {
    toast.error("Data error");
    console.log(error , "erorr")
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      clearUserData();
      state.user = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
      })
      .addCase(loginAsync.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export const { logout } = authSlice.actions;
export const selectLoginAuth = (state) => state.auth;
export default authSlice.reducer;
