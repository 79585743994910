import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useDispatch } from "react-redux";
import { GetTotalCount } from "../../redux/user/userSlice";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import carimage from "../../../src/assets/images/car.svg";
import companyImg from "../../../src/assets/images/building.svg";
import driverImg from "../../../src/assets/images/driver.svg";
import towingImg from "../../../src/assets/images/towing.svg"

function Dashboard() {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState()


  const getTotalCount = () => {
    dispatch(GetTotalCount())
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj?.data, "objectAplication");
        setTotalCount(obj?.data?.payload)
      })
      .catch((obj) => {
        console.log("err0r");
      });
  }
  useEffect(() => {
    getTotalCount()
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} height="100%">
              <ComplexStatisticsCard
                color="dark"
                icon={
                  <img
                    src={companyImg}
                    alt="Company"
                    style={{
                      width: '40px',
                      height: '40px',
                      filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)"
                    }}
                  />
                }
                title="Total No. of company"
                count={totalCount?.companiesCount}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}  height="100%">
              <ComplexStatisticsCard
                color="success"
                icon={
                  <img
                    src={carimage}
                    alt="Company"
                    style={{
                      width: '40px',
                      height: '40px',
                      filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)"
                    }}
                  />
                }
                title="Total No. of Vehicle Owner"
                count={totalCount?.carOwnersCount}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}  >
            <MDBox mb={1.5} height="100%">
              <ComplexStatisticsCard
                color="success"
                newColor = "darkBlue"
                icon={
                  <img
                    src={driverImg}
                    alt="Company"
                    style={{
                      width: '40px',
                      height: '40px',
                      filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)"
                    }}
                  />
                }
                title="Total No. of Drivers"
                count={totalCount?.driversCount}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}  >
            <MDBox mb={1.5} height="100%">
              <ComplexStatisticsCard
                color="success"
                newColor = "darkGreen"
                icon={
                  <img
                    src={towingImg}
                    alt="Company"
                    style={{
                      width: '40px',
                      height: '40px',
                      filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)"
                    }}
                  />
                }
                title="Total No. of Towing"
                count={totalCount?.towingComapniesCount}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
