import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import MDButton from 'components/MDButton';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 585,
    maxWidth: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};


const ProfileConfirmModal = (props) => {
const { open, onClose, onConfirm ,status } = props

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px"
                        }}
                        onClick={onClose}
                    >
                        <CancelIcon />
                    </IconButton>
                    <Box
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                            borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                            paddingBottom: "16px"
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: "24px",
                                fontWeight: "500",
                                color: "#000000"
                            }}>
                            Are you sure you want to {status}?
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <MDButton
                            component="a"
                            target="_blank"
                            rel="noreferrer"
                            variant="gradient"
                            onClick={onConfirm}
                            sx={{
                                color: "#ffffff",
                                background: "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                                marginTop: "24px",
                                textTransform: "capitalize",
                                fontSize: 14,
                                padding: "12px 34px"
                            }}
                        >
                            Yes , {status}
                        </MDButton>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ProfileConfirmModal