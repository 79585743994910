import { Navigate, Outlet } from "react-router-dom";
import screens from "../constants/Screens";
import secureLocalStorage from "react-secure-storage";

const PrivateCheck = ({ auth }) => {
    const authToken = secureLocalStorage.getItem("authToken") ? true : false;

    return (
        <>
            {
                (authToken && auth) ? <Outlet /> :
                    (!authToken && !auth) ? <Outlet /> : <Navigate to={screens.AUTH.LOGIN} />
            }
        </>
    )
}

export default PrivateCheck;